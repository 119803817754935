<template>
    <el-dialog
    title="校验码"
    :visible.sync="dialogVisible"
    width="1200px"
    :before-close="handleClose">
        <div style="padding-bottom:20px">
            <el-button type="primary" @click="clickAddAuthBtn">添加授权</el-button>
            <el-button type="primary" @click="updateAuthBtn">更新授权码</el-button>
        </div>
        <el-form :inline="true" label-width="80px">
            <el-form-item label="设备标识:">
                <el-input clearable v-model="params.yaMac" size="mini" @change="changeQueryParmas" />
            </el-form-item>
            <el-form-item label="状态:">
                <el-select size="mini" clearable v-model="params.yaState" @change="changeQueryParmas">
                    <el-option label="已使用" value="已使用"></el-option>
                    <el-option label="未使用" value="未使用"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="房间地址:">
                <el-input clearable v-model="params.hsAddCommunity" size="mini" @change="changeQueryParmas" />
            </el-form-item>
        </el-form>
        <div>
            <el-table border height="400px" @selection-change="handleSelectionChange" :data="tableData" v-loading="loading" empty-text="暂无授权码信息">
                <template v-for="item in column">
                    <el-table-column show-overflow-tooltip type="selection" v-if="item.label === '复选框'" :key="item.key"/>
                    <el-table-column 
                    show-overflow-tooltip
                    v-if="item.label != '复选框' && !item.address && !item.color"
                    :label="item.label"
                    :key="item.key" :prop="item.key" />
                    <el-table-column show-overflow-tooltip width="180" :label="item.label" v-if="item.address" :key="item.key" :prop="item.key">
                        <template slot-scope="scope">
                            <span>{{ scope.row[item.key] ? scope.row[item.key] : '' }} </span>
                            <span>{{ scope.row[item.key1] ? scope.row[item.key1] : '' }} </span>
                            <span>{{ scope.row[item.key2] ? scope.row[item.key2] : ''}} </span>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip  width="180" :label="item.label" v-if="item.color" :key="item.key" :prop="item.key">
                        <template slot-scope="scope">
                            <span :style="{color:item.color}">
                                {{ scope.row[item.key] }}
                            </span>
                        </template>
                    </el-table-column>
                </template>
            </el-table>
        </div>
        <div class="center">
                    <el-pagination
                    :page-sizes="[5,10]"
                    @size-change="handleSizeChange"
                    background
                    @current-change="changePage"
                    :page-size="params.limit"
                    layout="sizes, prev, pager, next"
                    :total="total" />
                </div>
        <AddCheckCodeDialog ref="addCheckCodeDialog" :dialogVisible="addAuthDialogVisible" @handleClose="handleAddAuthClose"/>
    </el-dialog>
</template>

<script>
import { getRoomPage,updAuthorize } from '@/api/room'
import AddCheckCodeDialog from './addCheckCodeDialog'
const column = [
    {
        label:'复选框',
        key:'1'
    },
    {
        label:'城区',
        key:'hsAddDistrict'
    },
    {
        label:'房间地址',
        key:'hsAddCommunity',
        key1:'hsAddBuilding',
        key2:'hsAddDoorplateno',
        address:true,
    },
    {
        label:'设备mac',
        key:'yaMac'
    },
    {
        label:'授权码',
        key:'yaCode'
    },
    {
        label:'授权码状态',
        key:'yaState'
    },
    {
        label:'授权时间',
        key:'yaTime',
        color:'#41b883'
    }
]

export default {
    props:{
        dialogVisible:Boolean
    },
    components:{
        AddCheckCodeDialog
    },
    data(){
        return {
            loading:false,
            column,
            addAuthDialogVisible:false,
            selectUpdateAuth:[],
            params:{
                limit:10,
                cursor:1,
                yaMac:'',
                yaState:'',
                hsAddCommunity:''
            },
            total:null,
            tableData:[]
        }
    },
    methods:{
        handleSelectionChange(val){
            this.selectUpdateAuth = val.map(item => item.yaId)
        },
        handleAddAuthClose(flag){
            this.addAuthDialogVisible = false
            if(flag){
                this.getRoomPageData()
            }
        },
        clickAddAuthBtn(){
            this.addAuthDialogVisible = true
            this.$refs['addCheckCodeDialog'].getSceneInfo()
        },
        updateAuthBtn(){
            if(this.selectUpdateAuth.length === 0){
                this.$message({
                    type:'warning',
                    message:'请勾选一行或者多行'
                })
                return
            }
            updAuthorize({yaIds:this.selectUpdateAuth}).then(res => {
                this.getRoomPageData()
                this.$message({
                    type:'success',
                    message:'更新成功'
                })
            })
        },
        handleClose(){
            Object.assign(this.params, this.$options.data().params)
            this.$emit("handleCheckDialogClose")
        },
        changeQueryParmas(){
            this.getRoomPageData()
        },
        changePage(val){
            this.params.cursor = val
            this.getRoomPageData()
        },
        handleSizeChange(val){
            this.params.limit = val
            this.getRoomPageData()
        },
        getRoomPageData(){
            this.loading = true
            getRoomPage(this.params).then(res => {
                this.loading = false
                this.tableData = res.data.result.records
                this.total = res.data.result.total
            }).catch(err => {
                this.loading = false
                this.$message({
                    type:'warning',
                    message:'暂无授码信息'
                })
            })
        }
    },
    
}
</script>

<style scoped>
.center{
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>