<template>
  <div v-loading="loading">
    <div ref="screen" class="input">
      <!--    顶部选项按钮 开始-->
      <el-row>
        <el-button class="buttons" type="primary" @click="addRoom" v-if="authe.Hotel_Core_RoomManage_AddRoom" size="mini">添加客房</el-button>
          <el-dialog title="添加客房" v-loading="room.loading" :visible.sync="room.dialogRoom" @close="closeDialogRoom" :close-on-click-modal="false" width="670px">
            <el-form inline :model="room.params" ref="roomNew" :rules="room.rules" :label-width="screenLabelWidth">
              <el-row>
                <el-form-item label="选择地址" prop="jcuId" class="divwrap">
                  <v-distpicker @selected="roomChanceCity" :province="room.province" :city="room.city" :area="room.area"></v-distpicker>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="门店名称" prop="hsHouseDictId">
                   <el-select v-model="room.params.hsHouseDictId" @focus="verDataStore" size="mini" class="screenInput">
                    <el-option v-for="(value,i) in room.dataStore" :key="i" :label="value.hdCommunity" :value="value.hdId"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="房号" prop="hsAddDoorplateno">
                  <el-input v-model="room.params.hsAddDoorplateno" size="mini" class="screenInput"></el-input>
                </el-form-item>
                <el-form-item label="房间楼层" prop="hsAddFloor">
                  <el-input v-model="room.params.hsAddFloor" oninput="value=value.replace(/[^\d]/g,'')" size="mini" class="screenInput"></el-input>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="客房类型">
                  <el-select v-model="room.params.hsRoomType" @change="roomTypeChange(room.params.hsRoomType,room.params,'hsResidentiality')" size="mini" class="screenInput">
                    <el-option v-for="(value,i) in JSON.parse(setupList.jsrsuRoomType)" :key="i" :label="value.roomType" :value="value.roomType"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="可住人数">
                  <el-input v-model="room.params.hsResidentiality" size="mini" class="screenInput"></el-input>
                </el-form-item>
              </el-row>
            </el-form>
            <div slot="footer">
              <el-button @click="room.dialogRoom = false">取 消</el-button>
              <el-button type="primary" @click="addRoomSave">添 加</el-button>
            </div>
          </el-dialog>
        <el-button class="buttons" type="primary" @click="batchAddRoom" v-if="authe.Hotel_Core_RoomManage_BatchAddRoom" size="mini">批量添加客房</el-button>
          <el-dialog title="添加集中式客房" :visible.sync="batch.dialogBatch" @close="closeDialogBatch" append-to-body :close-on-click-modal="false" width="1000px">

            <el-steps :active="batch.active" finish-status="success" simple style="margin-top: 20px">
              <el-step title="房源信息"></el-step>
              <el-step title="生成集中房"></el-step>
              <el-step title="参数设置"></el-step>
              <el-step title="预览房间"></el-step>
            </el-steps>

            <div v-if="batch.active == 0" style="margin-top: 10px">
              <el-form inline :model="room.params" ref="roomNew" :rules="room.rules" :label-width="screenLabelWidth">
                <el-row>
                  <el-form-item label="选择地址" prop="jcuId" class="divwrap">
                    <el-input v-model="batch.first.province" disabled size="mini" class="screenInput" style="margin-right: 10px"></el-input>
                    <el-input v-model="batch.first.city" disabled size="mini" class="screenInput" style="margin-right: 10px"></el-input>
                    <el-select v-model="batch.first.area" size="mini" class="screenInput">
                      <el-option v-for="(value,i) in cityList" :key="i" :label="value.hdDistrict" :value="value.hdDistrict"></el-option>
                    </el-select>
                  </el-form-item>
                </el-row>
              </el-form>
            </div>
            <div v-if="batch.active == 1" style="margin-top: 10px">
              <el-row>
                <el-button size="mini" @click="addTemperas">新增规则</el-button>
                <el-button size="mini" @click="delTemperas">删除规则</el-button>
                <el-checkbox v-model="batch.second.floorCheck">楼层小于10不补0</el-checkbox>
                <el-checkbox v-model="batch.second.roomCheck">房号小于10不补0</el-checkbox>
              </el-row>
              <el-table
              :data="batch.second.temperasList"
              @row-click="temperasClick"
              height="405px"
              size="mini"
              style="width: 100%" >
                <el-table-column label="门店名称" align="center" min-width="180px">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.community" v-if="scope.row.inputShow" placeholder="必填" size="mini" style="width:170px"></el-input>
                    <span v-else style="width:90px">{{scope.row.community}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="开始层数" align="center" width="100px">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.beginFloor" oninput="value=value.replace(/[^\d]/g,'')" v-if="scope.row.inputShow" placeholder="必填" size="mini" style="width:90px"></el-input>
                    <span v-else style="width:90px">{{scope.row.beginFloor}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="结束层数" align="center" width="100px">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.endFloor" oninput="value=value.replace(/[^\d]/g,'')" v-if="scope.row.inputShow" placeholder="必填" size="mini" style="width:90px"></el-input>
                    <span v-else style="width:90px">{{scope.row.endFloor}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="开始房间号" align="center" width="100px">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.startRoomNum" v-if="scope.row.inputShow" placeholder="必填" size="mini" style="width:90px"></el-input>
                    <span v-else style="width:90px">{{scope.row.startRoomNum}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="结束房间号" align="center" width="100px">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.endRoomNum" v-if="scope.row.inputShow" placeholder="必填" size="mini" style="width:90px"></el-input>
                    <span v-else style="width:90px">{{scope.row.endRoomNum}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="房号命名方案" align="center" show-overflow-tooltip width="200px">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.namePlan" v-if="scope.row.inputShow" @change="namePlanChange(scope.row)" placeholder="必填" size="mini" style="width:190px">
                      <el-option v-for="(item,i) in batch.roomPlanList" :key="i" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <span v-else style="width:190px">{{scope.row.namePlanSpan}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="楼层前缀" align="center" width="80px">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.floorNumPrefix" v-if="scope.row.inputShow" size="mini" style="width:70px"></el-input>
                    <span v-else style="width:60px">{{scope.row.floorNumPrefix}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="房号前缀" align="center" width="80px">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.roomNumPrefix" v-if="scope.row.inputShow" size="mini" style="width:70px"></el-input>
                    <span v-else style="width:60px">{{scope.row.roomNumPrefix}}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div v-if="batch.active == 2" style="margin-top: 10px">
              <el-row>
                <el-button size="mini" @click="addParameter">新增参数</el-button>
                <el-button size="mini" @click="delParameter">删除参数</el-button>
              </el-row>
              <el-table
              :data="batch.third.parameterList"
              @row-click="parameterClick"
              height="405px"
              size="mini"
              style="width: 100%">
                <el-table-column label="房间类型" align="center" min-width="180px">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.roomType" @change="roomTypeChange(scope.row.roomType,scope.row,'maxPeople')" v-if="scope.row.inputShow2" placeholder="必填" size="mini" style="width:170px">
                      <el-option v-for="(value,i) in JSON.parse(setupList.jsrsuRoomType)" :key="i" :label="value.roomType" :value="value.roomType"></el-option>
                    </el-select>
                    <span v-else style="width:90px">{{scope.row.roomType}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="可居住人数" align="center" min-width="180px">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.maxPeople" v-if="scope.row.inputShow2" placeholder="必填" size="mini" style="width:170px"></el-input>
                    <span v-else style="width:90px">{{scope.row.maxPeople}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="关联" align="center" min-width="180px">
                  <template slot-scope="scope">
                    <el-button type="text" @click="associatedClick(scope.row)" style="color: red">关联</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
              <el-dialog width="1150px" title="添加关联房" :visible.sync="batch.third.dialogAssociated" @close="closeDialogAssociated" :close-on-click-modal="false" append-to-body>
                <el-form inline>
                  <el-form-item label="门店">
                    <el-input v-model="batch.third.params.community" @change="getAssociatedLeftList" filterable clearable size="mini" style="width:100px"></el-input>
                  </el-form-item>
                  <el-form-item label="楼层">
                    <el-input v-model="batch.third.params.floor" @change="getAssociatedLeftList" filterable clearable size="mini" style="width:100px"></el-input>
                  </el-form-item>
                  <el-form-item label="房间号">
                    <el-input v-model="batch.third.params.doorplateno" @change="getAssociatedLeftList" filterable clearable size="mini" style="width:100px"></el-input>
                  </el-form-item>
                  <el-form-item label="楼层前缀">
                    <el-input v-model="batch.third.params.floorNumPrefix" @change="getAssociatedLeftList" filterable clearable size="mini" style="width:100px"></el-input>
                  </el-form-item>
                  <el-form-item label="房号前缀">
                    <el-input v-model="batch.third.params.roomNumPrefix" @change="getAssociatedLeftList" filterable clearable size="mini" style="width:100px"></el-input>
                  </el-form-item>
                </el-form>
                <el-row>
                  <el-col :span="11">
                    <el-table
                    :data='batch.third.associatedList'
                    @selection-change='associatedChange'
                    height="405px"
                    style="width: 100%" >
                      <el-table-column type="selection" width="55"></el-table-column>
                      <el-table-column align='center' prop="community" label="门店" show-overflow-tooltip></el-table-column>
                      <el-table-column align='center' prop="floor" label="楼层" width="60px"></el-table-column>
                      <el-table-column align='center' prop="doorplateno" label="房间号" width="70px"></el-table-column>
                      <el-table-column align='center' prop="floorNumPrefix" label="楼层前缀" width="80px"></el-table-column>
                      <el-table-column align='center' prop="roomNumPrefix" label="房号前缀" width="80px"></el-table-column>
                    </el-table>
                  </el-col>
                  <el-col :span="2" style="margin-top:150px;text-align:center">
                    <el-row>
                      <el-button type="primary" icon="el-icon-arrow-right" @click="addBig" circle></el-button>
                    </el-row>
                    <el-row style="margin-top:10px">
                      <el-button type="primary" icon="el-icon-arrow-left" @click="removeBig" circle></el-button>
                    </el-row>
                  </el-col>
                  <el-col :span="11">
                    <el-table
                    :data='batch.third.associatedList2'
                    @selection-change='associatedChange2'
                    height="405px"
                    style="width: 100%" >
                      <el-table-column type="selection" width="55"></el-table-column>
                      <el-table-column align='center' prop="community" label="门店" show-overflow-tooltip></el-table-column>
                      <el-table-column align='center' prop="floor" label="楼层" width="60px"></el-table-column>
                      <el-table-column align='center' prop="doorplateno" label="房间号" width="70px"></el-table-column>
                      <el-table-column align='center' prop="floorNumPrefix" label="楼层前缀" width="80px"></el-table-column>
                      <el-table-column align='center' prop="roomNumPrefix" label="房号前缀" width="80px"></el-table-column>
                    </el-table>
                  </el-col>
                  <el-col align="center" style="margin-top: 10px">
                    <el-button type="primary" @click="updateTemperas">保存</el-button>
                    <el-button @click="batch.third.dialogAssociated = false" style="margin-left: 30px">取消</el-button>
                  </el-col>
                </el-row>
              </el-dialog>
            <div v-if="batch.active == 3" style="margin-top: 10px">
              <el-row>
                <el-button size="mini" @click="addNewRoom">新增房间</el-button>
                <el-button size="mini" @click="delNewRoom">删除房间</el-button>
              </el-row>
              <el-table
              :data="batch.fourth.lastStepList"
              @row-click="lastStepClick"
              @selection-change='lastStepChange'
              :key="batch.fourth.testKey"
              height="405px"
              size="mini"
              style="width: 100%">
                <el-table-column type="selection" width="45"></el-table-column>
                <el-table-column label="门店名称" align="center" min-width="180px">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.community" v-if="scope.row.inputShow3" placeholder="必填" size="mini" style="width: 170px"></el-input>
                    <span v-else style="width:90px">{{scope.row.community}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="房号" align="center" min-width="180px">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.doorplateno" v-if="scope.row.inputShow3" placeholder="必填" size="mini" style="width: 170px"></el-input>
                    <span v-else style="width:90px">{{scope.row.doorplateno}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="房间类型" align="center" min-width="180px">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.roomType" @change="roomTypeChange(scope.row.roomType,scope.row,'maxPeople')" v-if="scope.row.inputShow3" placeholder="必填" size="mini" style="width:170px">
                      <el-option v-for="(value,i) in JSON.parse(setupList.jsrsuRoomType)" :key="i" :label="value.roomType" :value="value.roomType"></el-option>
                    </el-select>
                    <span v-else style="width:90px">{{scope.row.roomType}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="可居住人数" align="center" min-width="180px">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.maxPeople" v-if="scope.row.inputShow3" placeholder="必填" size="mini" style="width: 170px"></el-input>
                    <span v-else style="width:90px">{{scope.row.maxPeople}}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div slot="footer">
              <el-button v-show="this.batch.active > 0" @click="batchRoomPrev">上一步</el-button>
              <el-button @click="batch.dialogBatch = false">取 消</el-button>
              <el-button type="primary" v-show="this.batch.active < 3" @click="batchRoomNext">下一步</el-button>
              <el-button type="primary" v-show="this.batch.active == 3" @click="batchAddRoomSave">保 存</el-button>
            </div>
          </el-dialog>
        <el-button class="buttons" type="primary" @click="modifyState(2,2,'批量发布')" v-if="authe.Hotel_Core_RoomManage_BatchRelease" size="mini">批量发布</el-button>
        <el-button class="buttons" type="primary" @click="modifyState(2,1,'批量下架')" v-if="authe.Hotel_Core_RoomManage_BatchTakeOff" size="mini">批量下架</el-button>
        <el-button class="buttons" type="primary" @click="modifyState(1,1,'批量注销')" v-if="authe.Hotel_Core_RoomManage_BatchLogout" size="mini">批量注销</el-button>
        <el-button class="buttons" type="primary" @click="modifyState(1,2,'批量启用')" v-if="authe.Hotel_Core_RoomManage_BatchEnable" size="mini">批量启用</el-button>
        <el-button class="buttons" type="primary" @click="pageStoreManagement" v-if="authe.Hotel_Core_RoomManage_StoreManagement" size="mini">门店管理</el-button>
          <el-dialog title="门店管理" :visible.sync="storeManagement.dialogTableStore" @close="closeDialogTableStore" :close-on-click-modal="false" width="670px">
            <el-row>
              <el-button type="primary" @click="addStore" size="mini">添加门店</el-button>
                <el-dialog :title="`${storeManagement.store.title}门店`" :visible.sync="storeManagement.store.dialogStore" @close="closeDialogStore" append-to-body :close-on-click-modal="false" width="670px">
                  <el-form inline :model="storeManagement.store.houseDictPARM" ref="storeNew" :rules="storeManagement.store.rules" :label-width="screenLabelWidth">
                    <el-row>
                      <el-form-item label="选择地址" prop="hdDistrict" class="divwrap">
                        <!-- <v-distpicker @selected="storeChanceCity" :province="storeManagement.store.houseDictPARM.hdProvince" province-disabled :city="storeManagement.store.houseDictPARM.hdCity" city-disabled :area="storeManagement.store.houseDictPARM.hdDistrict"></v-distpicker> -->
                        <!-- @province="onChangeProvince" @city="onChangeCity" @area="onChangeArea" -->
                        <!--  -->
                        <!-- @province="onChangeProvince" @city="onChangeCity" @area="onChangeArea" -->
                        <v-distpicker  
                        @province="onChangeProvince" @city="onChangeCity" @area="onChangeArea"
                        :province="storeManagement.store.houseDictPARM.hdProvince" 
                        :city="storeManagement.store.houseDictPARM.hdCity" :area="storeManagement.store.houseDictPARM.hdDistrict"></v-distpicker>
                      </el-form-item>
                    </el-row>
                    <el-row>
                      <el-form-item label="地址" prop="hdRoad">
                        <el-input v-model="storeManagement.store.houseDictPARM.hdRoad" @change="test" size="mini" style="width: 335px"></el-input>
                      </el-form-item>
                      <el-form-item label="经度-x">
                        <el-input v-model="storeManagement.store.houseDictPARM.hdBaiduLongitudeX" readonly size="mini" class="screenInput"></el-input>
                      </el-form-item>
                    </el-row>
                    <el-row>
                      <el-form-item label="门店" prop="hdCommunity">
                        <el-input v-model="storeManagement.store.houseDictPARM.hdCommunity" @change="test" size="mini" style="width: 335px"></el-input>
                      </el-form-item>
                      <el-form-item label="纬度-y">
                        <el-input v-model="storeManagement.store.houseDictPARM.hdBaiduLatitudeY" readonly size="mini" class="screenInput"></el-input>
                      </el-form-item>
                    </el-row>
                    <baidu-map id='maps' v-show="false" :center="center" @ready="handler" :zoom="zoom" :scroll-wheel-zoom='true'></baidu-map>
                  </el-form>
                  <div slot="footer">
                    <el-button @click="storeManagement.store.dialogStore = false">取 消</el-button>
                    <el-button type="primary" v-if="storeManagement.store.state" @click="addStoreSave">添 加</el-button>
                    <el-button type="primary" v-else @click="modifyStoreSave">修 改</el-button>
                  </div>
                </el-dialog>
            </el-row>
            <el-row class="screen">
              <el-form inline label-width="50px">
                <el-form-item label="城区">
                  <el-select v-model="storeManagement.data.params.hdDistrict" @change="storeManagementSearchChange" filterable clearable size="mini" class="screenInput">
                    <el-option v-for="(value,i) in cityList" :key="i" :label="value.hdDistrict" :value="value.hdDistrict"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="地址">
                  <el-input v-model="storeManagement.data.params.hdRoad" @change="storeManagementSearchChange" filterable clearable size="mini" class="screenInput"></el-input>
                </el-form-item>
                <el-form-item label="门店">
                  <el-input v-model="storeManagement.data.params.hdCommunity" @change="storeManagementSearchChange" filterable clearable size="mini" class="screenInput"></el-input>
                </el-form-item>
              </el-form>
            </el-row>
            <div style="text-align:center;">
              <el-table
              :data="storeManagement.dataList"
              highlight-current-row
              @current-change="storeManagementTableCurrentChange"
              @row-dblclick="modifyStore"
              height="288px" >
                <el-table-column prop="hdCity" label="城市" min-width="50px"></el-table-column>
                <el-table-column prop="hdDistrict" label="城区" min-width="50px"></el-table-column>
                <el-table-column prop="hdRoad" label="地址" min-width="50px"></el-table-column>
                <el-table-column prop="hdCommunity" label="门店" min-width="50px"></el-table-column>
              </el-table>
              <el-pagination
                background layout="total, sizes, prev, pager, next, jumper"
                :page-count="storeManagement.data.pages"
                :page-size="storeManagement.data.params.limit"
                :total="storeManagement.data.total"
                :current-page="storeManagement.data.params.cursor"
                @size-change="storeManagementSizeChange"
                @current-change="storeManagementCurrentChange"
                :page-sizes="[5,10,20,40]"
                ></el-pagination>
            </div>
          </el-dialog>
        <el-button class="buttons" type="primary" @click="bindingDevice" v-if="authe.Hotel_Core_RoomManage_BindingDevice" size="mini">绑定红外监测设备</el-button>
          <el-dialog title="绑定红外监测设备" :visible.sync="infrared.dialogBindingDevice" @close="cleanBindingDevice" :close-on-click-modal="false" width="900px">
            <div v-loading="infrared.loading" class="transferStyle" style="display: flex; align-items: center; justify-content: center;">
              <el-transfer
                :data="infrared.allMonitorDevices"
                v-model="infrared.monitoredDevices"
                :props="{key: 'jourDeviceId',label: 'devNickname'}"
                :titles="['未检测列表','已检测列表']"
                filterable
                filter-placeholder="搜索设备名称"
              ></el-transfer>
            </div>
            <div slot="footer">
              <el-button @click="infrared.dialogBindingDevice = false">取 消</el-button>
              <el-button type="primary" @click="bindingDeviceSave" v-if="authe.Hotel_Core_RoomManage_BindingDeviceSave">保 存</el-button>
            </div>
          </el-dialog>
        <el-button class="buttons" type="primary" @click="printCode" v-if="authe.Hotel_Core_RoomManage_PrintQRCode" size="mini">打印二维码</el-button>
          <el-dialog width="600px" title="打印二维码" :visible.sync="printCodeForm.dialogprintCode" :close-on-click-modal="false" append-to-body>
            <div style='width:100%;margin:0 auto;' id='code'>
              <div v-for='(item,i) in printCodeForm.urlList' :key='i' style="text-align:center">
                <vue-qr :text="item.url" :margin='0' colorDark="#333" colorLight="#fff" :size="120" style="text-align:center"></vue-qr>
                <!-- <p>通过扫码开门：微信/QQ/支付宝</p> -->
                <p>{{item.address}}</p>
              </div>
            </div>
            <div slot="footer">
              <el-button @click="printCodeForm.dialogprintCode=false">取消</el-button>
              <el-button v-print="'#code'" type="primary">打印</el-button>
            </div>
          </el-dialog>
          <!-- v-if -->
          <el-button class="buttons" type="primary" size="mini" v-if="authe.Hotel_Core_RoomManage_CodeAuth" @click="clickCheckCodeBtn">校验码</el-button>
          <CheckCodeDialog ref="checkCodeDialog" :dialogVisible="checkCodeDialogVisible" @handleCheckDialogClose="handleCheckDialogClose"/>
          <!-- 客控码 -->
          <el-button class="buttons" type="primary" size="mini" @click="customerBtn">客控码</el-button>
          <RoomCustomer :customerVisible="customerDialogVisible"  @handleCustomerDialogClose="handleCustomerDialogClose"/>
          <!-- end -->
      </el-row>
      <!--    顶部选项按钮 结束-->
      <!--    筛选框 开始-->
      <el-row class="screen">
        <el-form inline :label-width="screenLabelWidth">
          <el-row>
            <el-row>
              <el-form-item label="城区">
                <el-select v-model="data.params.hsAddDistrict" @change="searchChange" filterable clearable size="mini" class="screenInput">
                  <el-option v-for="(value,i) in cityList" :key="i" :label="value.hdDistrict" :value="value.hdDistrict"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="门店">
                <el-input v-model="data.params.hsAddCommunity" @change="searchChange" filterable clearable size="mini" class="screenInput"></el-input>
              </el-form-item>
              <el-form-item label="房号">
                <el-input v-model="data.params.hsAddDoorplateno" @change="searchChange" filterable clearable size="mini" class="screenInput"></el-input>
              </el-form-item>
              <el-form-item label="房型">
                <el-select v-model="data.params.hsRoomType" @change="searchChange" filterable clearable size="mini" class="screenInput">
                  <el-option v-for="(value,i) in JSON.parse(setupList.jsrsuRoomType)" :key="i" :label="value.roomType" :value="value.roomType"></el-option>
                </el-select>
              </el-form-item>
              <el-button style="margin-top: 5px;" @click="btns=!btns"  class="buttons" type="primary" size="mini">高级筛选</el-button>
            </el-row>
            <el-row v-if="btns">
              <el-form-item label="发布状态">
                <el-select v-model="data.params.hsMicronetIdentification" @change="searchChange" filterable clearable size="mini" class="screenInput">
                  <el-option label="已发布" :value="2"></el-option>
                  <el-option label="未发布" :value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="客房状态">
                <el-select v-model="data.params.roomStatus" @change="searchChange" filterable clearable size="mini" class="screenInput">
                  <el-option label="空房" value="空房"></el-option>
                  <el-option label="已住" value="已住"></el-option>
                  <el-option label="预定" value="预定"></el-option>
                  <el-option label="保留" value="保留"></el-option>
                  <el-option label="退定" value="退定"></el-option>
                  <el-option label="退定中" value="退定中"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="房间状态">
                <el-select v-model="data.params.hsState" @change="searchChange" filterable clearable size="mini" class="screenInput">
                  <el-option label="正常" value="正常"></el-option>
                  <el-option label="注销" value="注销"></el-option>
                </el-select>
              </el-form-item>
            </el-row>
          </el-row>
        </el-form>
      </el-row>
      <!--    筛选框 结束-->
    </div>

    <!--    房间详情列表 开始-->
      <room-detail
        v-if="roomDetailDialog"
        :isDialogShow="roomDetailDialog"
        :isRowData="roomDetailRowData"
        :isPageName="'RoomManage'"
        @close="roomDetailClose"
      >
      </room-detail>
    <!--    房间详情列表 结束-->

    <!--    数据列表 开始-->
    <div style="text-align:center;">
      <!--    表格显示列表 开始-->
      <el-table
      :data="dataList"
      highlight-current-row
      @current-change="tableCurrentChange"
      @row-dblclick="roomDetailDblclick"
      @selection-change="selectionChange"
      :height="tableHeight">
        <el-table-column align="center" type="selection" width="45"></el-table-column>
        <el-table-column align="center" prop="hsAddDistrict" label="城区" min-width="50px"></el-table-column>
        <el-table-column align="center" prop="address" label="客房地址" show-overflow-tooltip min-width="100px"></el-table-column>
        <el-table-column align="center" prop="hsResidentiality" label="可住人数" min-width="50px"></el-table-column>
        <el-table-column align="center" prop="hsRoomType" label="客房类型" min-width="50px"></el-table-column>
        <el-table-column align="center" prop="hsMicronetIdentification" :formatter="formatHsMicronetIdentification" label="发布状态" min-width="50px"></el-table-column>
        <el-table-column align="center" prop="jsrcState" label="客房状态" :formatter="formatJsrcState" min-width="50px"></el-table-column>
        <el-table-column align="center" prop="hsState" label="房间状态" min-width="50px"></el-table-column>
      </el-table>
      <!--    表格显示列表 结束-->

      <!--    分页 开始-->
      <el-pagination
        background layout="total, sizes, prev, pager, next, jumper"
        :page-count="data.pages"
        :page-size="data.params.limit"
        :total="data.total"
        :current-page="data.params.cursor"
        @current-change="currentChange"
        @size-change="sizeChange"
        :page-sizes="[5,10,20,40]"
        ></el-pagination>
      <!--    分页 结束-->
    </div>
    <!--    数据列表 结束-->
  </div>
</template>
<script>
import { mapState , mapActions } from 'vuex'
import roomDetail from './components/roomDetail'
import CheckCodeDialog from './room-cpns/checkCodeDialog/index'
import RoomCustomer from './room-customer/index'
import moment from 'moment'
export default {
  components: {
    'room-detail': roomDetail,
    CheckCodeDialog,
    RoomCustomer
  },
  name: 'room',
  data(){
    return{
      customerDialogVisible:false,
      checkCodeDialogVisible:false,
      btns:false,
      loading: false,
      screenLabelWidth: '75px',
      room: {//添加房间
        params: {
          hsHouseDictId: '',//门店名称
          hsAddDoorplateno: '',//房号
          hsResidentiality: '',//可住人数
          hsAddFloor: '',//房间楼层
          hsRoomType: '',//客房类型
        },
        loading: false,
        dialogRoom: false,
        province: '',
        city: '',
        area: '',
        dataStore: [],
        ruleArray: ['roomNew'],
        rules: {//验证规则
          hsHouseDictId: [
            {required: true,message: '门店名称不能为空',trigger: 'change',},
          ],
          hsAddDoorplateno: [
            {required: true,message: '房号不能为空',trigger: 'blur',},
            {min: 1,max: 50,message: '房号不能为空长度为 1 到 50 个字符',trigger: 'blur',}
          ],
          hsAddFloor: [
            {required: true,message: '房间楼层不能为空',trigger: 'blur',},
            {min: 1,max: 50,message: '房间楼层长度为 1 到 50 个字符',trigger: 'blur',}
          ],
        },
      },
      roomDetailRowData: null,
      roomDetailDialog: false,
      batch: {//批量添加客房
        dialogBatch: false,
        active: 0,
        roomPlanList: [
          {
            label:'数字楼层+数字房号',
            value:'1'
          },{
            label:'数字楼层+字母房号',
            value:'2'
          },{
            label:'楼层减一加A+数字房号',
            value:'3'
          },{
            label:'楼层减一加A+字母房号',
            value:'4'
          },{
            label:'楼层减一加A+数字房号减一加A',
            value:'5'
          },{
            label:'数字楼层+数字房号减一加A',
            value:'6'
          }
        ],
        first: {
          province: '',
          city: '',
          area: '',
        },
        second: {
          floorCheck: false,
          roomCheck: false,
          temperasList: [],
        },
        third: {
          parameterList: [],
          dialogAssociated: false,
          bigList:[],//关联房
          associatedList:[],//未关联房 {leftList:[],rightList:[]}
          associatedList2:[],//右关联房
          selList1:[],//左表勾选
          selList2:[],//右表勾选
          params: {
            identifying: '',
            roomNumPrefix: '',
            floorNumPrefix: '',
            roomNumber: '',
            floor: '',
            doorplateno: '',
            community: '',
            maxPeople: '',
            roomType: ''
          },
        },
        fourth: {
          lastStepList:[],//第五步数据表格
          lastSel:[],//第五步勾选
          testKey:0
        },
        updateParams:{
          ids: '',
          maxPeople: '',
          roomType: ''
        },
      },
      state: {//批量修改状态
        hsIds: [],    //未租房间数组集合
        type: '',     //服务端类型
        state: '',    //房间状态
      },
      storeManagement: {//门店管理
        store: {
          state: false,
          title: '',
          dialogStore: false,
          houseDictPARM: {
            hdProvince: '',//省
            hdCity: '',//市
            hdDistrict: '',//区
            hdRoad: '',//地址
            hdBaiduLongitudeX: '',//经度-x
            hdCommunity: '',//楼盘
            hdBaiduLatitudeY: '',//纬度-y
          },
          ruleArray: ['storeNew'],
          rules: {//验证规则
            hdRoad: [
              {required: true,message: '地址不能为空',trigger: 'blur',},
              {min: 1,max: 50,message: '地址不能为空长度为 1 到 50 个字符',trigger: 'blur',}
            ],
            hdCommunity: [
              {required: true,message: '楼盘不能为空',trigger: 'blur',},
              {min: 1,max: 50,message: '楼盘长度为 1 到 50 个字符',trigger: 'blur',}
            ],
          },
        },
        dialogTableStore: false,
        data: {//数据分页查询
          params: {
            hdDistrict: '',//城区
            hdRoad: '',//地址
            hdCommunity: '',//门店
            limit: 10,//每页的条数
            cursor: 1,//初始页
          },
          total: 0,//总条数
          pages: 0,//每页数据
        },
        dataList: [],
        rowData: null,
      },
      center: {
        lng: '22.566982',
        lat: '114.126489',
      },
      point: 18,
      zoom: 15,
      infrared: {//红外监测设备
        loading: false,
        dialogBindingDevice: false,
        allMonitorDevices: [],//所有设备
        monitoredDevices: [],//已绑定设备
      },
      tableHeight: window.innerHeight - 210,
      data: {
        params: {
          hsAddDistrict: '',//城区
          hsAddCommunity: '',//门店
          hsAddDoorplateno: '',//房号
          hsRoomType: '',//房型
          hsMicronetIdentification: '',//发布状态
          roomStatus: '',//客房状态
          hsState: '正常',//房间状态
          limit: 10,//每页的条数
          cursor: 1,//初始页
        },
        total: 0,//总条数
        pages: 0,//每页数据
      },
      rowData: null,
      dataList: [],
      dataChosenId: [],
      dataChosenArray: [],
      printCodeForm:{//打印二维码数据
        dialogprintCode: false,
        urlList: []
      },
    }
  },
  computed: {
    ...mapState(['setupList','cityList','userName','authe'])
  },
  watch:{
    $route(to,from){
      if(to.params.hsId) {
        this.roomDetailDblclick({hsId: to.params.hsId})
      }
    },
  },
  created(){
    if(this.$route.params.hsId) {
      this.roomDetailDblclick({hsId: this.$route.params.hsId})
    }
    this.getDataList()
  },
  mounted(){
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - this.$refs.screen.clientHeight - 140
    })
  },
  methods: {
    ...mapActions(['getCityList']),
    // 新增 校验码
    handleCheckDialogClose(){
      this.checkCodeDialogVisible = false
    },
    
    clickCheckCodeBtn(){
      this.checkCodeDialogVisible = true
      console.log(this.$refs['checkCodeDialog'],'123')
      this.$refs['checkCodeDialog'].getRoomPageData()
    },
    // 客控码
    customerBtn(){
      this.customerDialogVisible = true
    },
    handleCustomerDialogClose(){
      this.customerDialogVisible = false
    },
    // end
    getDataList(){//查询数据
      this.$axios({
        method: 'get',
        url: '/hotel/house4store',
        params: this.data.params
      }).then(res => {
        if (res.data.result.records.length > 0) {
          for (let i of res.data.result.records) {
            let a = i.hsAddCommunity ? `${i.hsAddCommunity}\u3000` : ''
            let b = i.hsRoomType ? `${i.hsRoomType}\u3000` : ''
            let c = i.hsAddDoorplateno ? `${i.hsAddDoorplateno}` : ''
            i.address =  a + b + c
          }
          this.dataList = res.data.result.records
          this.data.total = res.data.result.total
          this.rowData = null
        } else {
          this.dataList = []
          this.data.total = 0
          this.rowData = null
        }
        console.log(this.dataList)
      }).catch(err => {
        this.isError(err,this)
      })
    },
    formatHsMicronetIdentification(row, column) {
      return row.hsMicronetIdentification === 1 ? '未发布' : '已发布'
    },
    formatJsrcState(row, column) {
      return row.jsrcState === '退房' || row.jsrcState === '退定' || row.jsrcState === '取消保留' || row.jsrcState === undefined ? '空房' : row.jsrcState
    },
    searchChange(){//筛选条件改变时
      this.data.params.cursor = 1
      this.getDataList()
    },
    currentChange(cursor){//点击更改点前页数
      this.data.params.cursor = cursor;
      this.getDataList()
    },
    sizeChange(limit){//点击更改显示条数
      this.data.params.limit = limit;
      this.getDataList()
    },
    tableCurrentChange(row){//选中行
      this.rowData = row
    },
    selectionChange(row){//多选数组
      this.dataChosenArray = row
      let ids=[]
      for(let i of row){
        ids.push(i.hsId)
      }
      this.dataChosenId = ids
      console.log(this.dataChosenArray)
    },
  //添加客房
    addRoom(){//添加客房
      this.room.province = sessionStorage.getItem('companyRentProvince')
      this.room.city = sessionStorage.getItem('companyRentCity')
      this.room.dialogRoom = true
    },
    roomChanceCity(data){//城区选中时
      this.$nextTick(()=>{
        console.log(data)
      })
      if (data.area.value) {
        this.room.area = data.area.value
        this.$axios({
          method: 'get',
          url: '/hotel/houseDict',
          params: {
            hdDistrict: this.room.area
          },
        }).then(res => {
          this.room.dataStore = res.data.result
        }).catch(err => {
          this.isError(err,this)
        })
      } else {
        this.room.area = ''
        this.room.dataStore = []
      }
    },
    verDataStore(){//验证门店列表
    console.log(this.room.dataStore);
      if (this.room.dataStore.length < 1) {
        this.$message.warning('请先选择地址')
      }
    },
    roomTypeChange(value,obj,str) {//房型改变时给可住人数赋值
      for (let i of JSON.parse(this.setupList.jsrsuRoomType)) {
        if (value == i.roomType) {
          obj[str] = i.number
        }
      }
    },
    addRoomSave(){//添加客房保存
      if(this.submitForm(this.room.ruleArray,this) ){
        this.room.loading = true
        this.$axios({
          method: 'post',
          url: '/hotel/house4store',
          data: this.room.params
        }).then(res => {
          this.room.loading = false
          this.$message.success('添加成功')
          this.getDataList()
          this.room.dialogRoom = false
        }).catch(err => {
          this.room.loading = false
          this.isError(err,this)
        })
      }
    },
    closeDialogRoom(){//清空添加客房
      this.resetForm(this.room.ruleArray,this)
      this.room.params = {
        hsHouseDictId: '',//门店名称
        hsAddDoorplateno: '',//房号
        hsResidentiality: '',//可住人数
        hsAddFloor: '',//房间楼层
        hsRoomType: '',//客房类型
      },
      this.room.province = ''
      this.room.city = ''
      this.room.area = ''
      this.room.dataStore = []
    },
  //修改客房
    roomDetailDblclick(row) {
      this.roomDetailRowData = row
      this.roomDetailDialog = true
    },
    roomDetailClose() {
      this.getDataList()
      this.roomDetailDialog = false
    },
  //批量添加客房
    batchAddRoom(){//批量添加客房
      this.batch.first.province = sessionStorage.getItem('companyRentProvince')
      this.batch.first.city = sessionStorage.getItem('companyRentCity')
      this.batch.dialogBatch = true
    },
    //房源信息
    //生成集中房
      addTemperas(){//新增规则
        if (this.batch.second.temperasList.length > 0) {
          for(let i of this.batch.second.temperasList){
            if (!i.community || !i.beginFloor || !i.endFloor || !i.startRoomNum || !i.endRoomNum || !i.namePlan) {
              this.$message.warning('数据不完整')
              return
            }
          }
        }
        let obj = {
          inputShow: 1,//输入框显示
          community: '',//门店名称
          beginFloor: '',//开始层数
          endFloor: '',//结束层数
          startRoomNum: '',//开始房间
          endRoomNum: '',//结束房间
          namePlan: '',//房号命名方案
          floorNumPrefix: '',//楼层前缀
          roomNumPrefix: '',//房号前缀
          ckFloor: this.batch.second.floorCheck,//楼层小于10补零
          ckRoom: this.batch.second.roomCheck//房号小于10补零
        }
        if(this.batch.second.temperasList.length>0){
          for(let i of this.batch.second.temperasList){
            i.inputShow = 0
          }
          this.batch.second.temperasList.push(obj)
        }else{
          this.batch.second.temperasList.push(obj)
        }
      },
      delTemperas(){//删除规则
        if(this.batch.second.temperasList.length > 0){
          for(let i in this.batch.second.temperasList){
            if(this.batch.second.temperasList[i].inputShow==1){
              this.batch.second.temperasList.splice(i,1)
              return
            }
          }
        } else {
          this.$message.warning('没有规则可删除')
        }
      },
      namePlanChange(row){
        for (let i of this.batch.roomPlanList) {
          if (row.namePlan == i.value) {
            row.namePlanSpan = i.label
            return
          }
        }
      },
      temperasClick(row){//表格双击

        this.batch.second.temperasList.forEach(item=>{
          item.inputShow=0
        })
        row.inputShow=1
      },
    //参数设置
      addParameter(){//新增参数
        if (this.batch.third.parameterList.length > 0) {
          for(let i of this.batch.third.parameterList){
            if (!i.maxPeople || !i.roomType) {
              this.$message.warning('数据不完整')
              return
            }
          }
        }
        let obj={
          inputShow2:1,//输入框显示
          maxPeople: '',//可居住人数
          roomType: '',//房间类型
          associatedList:[],//关联表格
          onlyRandom: parseInt((Math.random() * 9 + 1) * 10000000)
        }
        if(this.batch.third.parameterList.length>0){
          for(let i of this.batch.third.parameterList){
            i.inputShow2=0
          }
          this.batch.third.parameterList.push(obj)
        }else{
          this.batch.third.parameterList.push(obj)
        }
      },
      delParameter(){//删除参数
        if(this.batch.third.parameterList.length > 0){
          for(let i in this.batch.third.parameterList){
            if(this.batch.third.parameterList[i].inputShow2==1){
              this.batch.third.parameterList.splice(i,1)
              return
            }
          }
        } else {
          this.$message.warning('没有参数可删除')
        }
      },
      parameterClick(row){//表格双击
        this.batch.third.parameterList.forEach(item=>{
          item.inputShow2=0
        })
        row.inputShow2=1
      },
      associatedClick(row){//关联点击
        if (row.maxPeople == '' && row.roomType == '') {
          this.$message.warning('请先设置关联参数！')
          return
        }
        console.log(row)
        this.batch.updateParams.maxPeople = row.maxPeople
        this.batch.updateParams.roomType = row.roomType
        this.batch.third.params.maxPeople = row.maxPeople
        this.batch.third.params.roomType = row.roomType
        this.getAssociatedLeftList()
        this.getAssociatedRightList()
        this.batch.third.dialogAssociated = true
      },
      getAssociatedLeftList(){
        let idList = []
        for (let i of this.batch.third.associatedList2) {
          idList.push(i.id)
        }
        idList = idList.length > 0 ? idList.join(",") : []
        this.$axios({
          method:'get',
          url:'/hotel/caTemporary/getAssociatedList',
          params: {
            identifying: this.batch.third.params.identifying,
            roomNumPrefix: this.batch.third.params.roomNumPrefix,
            floorNumPrefix: this.batch.third.params.floorNumPrefix,
            roomNumber: this.batch.third.params.roomNumber,
            floor: this.batch.third.params.floor,
            doorplateno: this.batch.third.params.doorplateno,
            community: this.batch.third.params.community,
            type: 'left',
            ids:idList
          },
        }).then(res =>{
          this.batch.third.associatedList = res.data.result
          // this.batch.fourth.lastStepList = res.data.result;
          // for (let i of this.batch.fourth.lastStepList){
          //   i.inputShow3 = 0;
          // }
        }).catch(err =>{
          this.isError(err,this)
        })
      },
      getAssociatedRightList(){
        this.$axios({
          method:'get',
          url:'/hotel/caTemporary/getAssociatedList',
          params: {
            identifying: this.batch.third.params.identifying,
            roomType: this.batch.third.params.roomType,
            maxPeople: this.batch.third.params.maxPeople,
            type: 'right'
          }
        }).then(res =>{
          this.batch.third.associatedList2 = res.data.result
        }).catch(err =>{
          this.isError(err,this)
        })
      },
    //修改临时房(添加人住人数和房间类型)
    updateTemperas(){
      if(this.batch.third.associatedList2.length == 0){
        this.$message.warning('请勾选需要添加设置参数的房间！')
      }else {
        let ids = [];
        for (let i of this.batch.third.associatedList2){
          ids.push(i.id);
        }
        this.batch.updateParams.ids = ids.join(",");
        this.$axios({
          method: 'put',
          url: '/hotel/caTemporary',
          params: this.batch.updateParams
        }).then(res=>{
          this.batch.third.dialogAssociated = false;
          this.$message.success('修改成功！')
        }).catch(err=>{
          this.batch.third.dialogAssociated = true;
          this.isError(err,this)
        })
      }
    },
    closeDialogAssociated(){
      this.batch.third.bigList = [];//关联房
      this.batch.third.associatedList = [];//未关联房
      this.batch.third.associatedList2 = [];//右关联房
      this.batch.third.selList1 = [];//左表勾选
      this.batch.third.selList2 = [];//右表勾选
      this.batch.third.params.roomNumPrefix = ''
      this.batch.third.params.floorNumPrefix = ''
      this.batch.third.params.roomNumber = ''
      this.batch.third.params.floor = ''
      this.batch.third.params.doorplateno = ''
      this.batch.third.params.community = ''
      this.batch.third.params.maxPeople = ''
      this.batch.third.params.roomType = ''
    },
      associatedChange(list){//表格一勾选
        console.log(list)
        this.batch.third.selList1=list
      },
      associatedChange2(list){//表格二勾选
        console.log(list)
        this.batch.third.selList2=list
      },
      addBig(){//添加按钮
        if(this.batch.third.selList1.length==0){
          this.$message.warning('请勾选需要添加关联的')
        }else{
          this.batch.third.selList1.forEach(item2=>{
            this.batch.third.bigList.push(item2)
            this.batch.third.associatedList2.push(item2)
            this.batch.third.associatedList.forEach((item,i)=>{
              if(this.batch.third.associatedList[i].id==item2.id){
                this.batch.third.associatedList.splice(i,1)
              }
            })
          })
        }
      },
      removeBig(){//移除按钮
        if(this.batch.third.selList2.length==0){
          this.$message.warning('请勾选需要移除的')
        }else{
          this.batch.third.selList2.forEach(item2=>{
            this.batch.third.associatedList.push(item2)
            this.batch.third.associatedList2.forEach((item,i)=>{
              if(this.batch.third.associatedList2[i].id==item2.id){
                this.batch.third.associatedList2.splice(i,1)
              }
            })
            this.batch.third.bigList.forEach((item,i)=>{
              if(this.batch.third.bigList[i].id==item2.id){
                this.batch.third.bigList.splice(i,1)
              }
            })
          })
        }
      },
    //预览房间
      addNewRoom(){//添加房间
        if (this.batch.fourth.lastStepList.length > 0) {
          for(let i of this.batch.fourth.lastStepList){
            if (!i.community || !i.roomNumber || !i.maxPeople || !i.roomType) {
              this.$message.warning('数据不完整')
              return
            }
          }
        }
        let obj={
          inputShow3:1,//输入框显示
          community: '',//门店名称
          roomNumber: '',//房号
          maxPeople:'',//可居住人数
          roomType:'',//房间类型
          onlyRandom: parseInt((Math.random() * 9 + 1) * 10000000)
        }
        if(this.batch.fourth.lastStepList.length>0){
          for(let i in this.batch.fourth.lastStepList){
            this.batch.fourth.lastStepList[i].inputShow3=0
          }
          this.batch.fourth.lastStepList.push(obj)
        }else{
          this.batch.fourth.lastStepList.push(obj)
        }
      },
      delNewRoom(){//删除房间
        if(this.batch.fourth.lastStepList.length > 0){
          for(let i in this.batch.fourth.lastStepList){
            if(this.batch.fourth.lastStepList[i].inputShow3==1){
              this.batch.fourth.lastStepList.splice(i,1)
              return
            }
          }
        } else {
          this.$message.warning('没有房间可删除')
        }
      },
      lastStepClick(row){//双击表格
        if(row.inputShow3){
          return
        }
        this.batch.fourth.lastStepList.forEach(item=>{
          item.inputShow3=0
        })
        row.inputShow3=1
        this.batch.fourth.testKey ++;
      },
      lastStepChange(list){//表格勾选
        this.batch.fourth.lastSel = list;
      },
    batchRoomNext(){//下一步
      if(this.batch.active == 0){
        if (!this.batch.first.area){
          this.$message.warning('城区不能为空')
          return
        }
      } else if (this.batch.active == 1){
        if (this.batch.second.temperasList.length > 0) {
          for(let i of this.batch.second.temperasList){
            if (!i.community || !i.beginFloor || !i.endFloor || !i.startRoomNum || !i.endRoomNum || !i.namePlan) {
              this.$message.warning('数据不完整')
              return
            }
            i.ckRoom = this.batch.second.roomCheck;
            i.ckFloor = this.batch.second.floorCheck;
          }
        } else {
          this.$message.warning('请添加规则')
          return
        }
        this.$axios({
          method:'post',
          url:'/hotel/caTemporary/temporary',
          data:this.batch.second.temperasList
        }).then(res => {
          this.batch.third.params.identifying = res.data.result
        }).catch(err =>{
          this.isError(err,this)
        })
      } else if (this.batch.active == 2){
        if (this.batch.third.parameterList.length > 0) {
          for(let i of this.batch.third.parameterList){
            if (!i.maxPeople || !i.roomType) {
              this.$message.warning('数据不完整')
              return
            }
          }
        } else {
          this.$message.warning('请添加规则')
          return
        }
        this.selectCaTemporary();
      } else if (this.batch.active == 3){
        if (this.batch.fourth.lastStepList.length > 0) {
          for(let i of this.batch.fourth.lastStepList){
            if (!i.community || !i.roomNumber || !i.maxPeople || !i.roomType) {
              this.$message.warning('数据不完整')
              return
            }
          }
        } else {
          this.$message.warning('请添加规则')
          return
        }
      }
      this.batch.active ++ > 3 ? this.batch.active = 3 : this.batch.active
    },
    //添加数据到临时表
    addCaTemporary(row){
      this.$axios({
        method:'post',
        url:'/hotel/caTemporary',
        data:row
      }).then(res =>{
        this.batch.third.params.identifying = row[0].identifying;
        this.selectCaTemporary();
      }).catch(err =>{
        this.isError(err,this)
      })
    },
    //跟据条件查询临时表数据
    selectCaTemporary(){
      this.$axios({
        method:'get',
        url:'/hotel/caTemporary',
        params: this.batch.third.params
      }).then(res =>{
        this.batch.fourth.lastStepList = res.data.result;
        for (let i of this.batch.fourth.lastStepList){
          i.inputShow3 = 0;
        }
        console.log(res.data.result)
      }).catch(err =>{
        this.isError(err,this)
      })
    },
    batchRoomPrev(){//上一步
      this.batch.active -- < 0 ? this.batch.active = 0 : this.batch.active
    },
    batchAddRoomSave(){//批量添加客房保存
      if(this.batch.fourth.lastSel == 0 ){
        this.$message.warning('至少需要选中一个房间！')
      }else {
        console.log("保存参数");
        this.batch.fourth.lastSel[0].hsAddDistrict = this.batch.first.area;
        console.log(this.batch.fourth.lastSel);
        this.$axios({
          method: 'post',
          url: '/hotel/house4store/creates',
          data: this.batch.fourth.lastSel
        }).then(res=> {
          this.batch.dialogBatch = false;
          this.$message.success('添加成功');
        }).catch(err => {
          this.isError(err,this)
        })
      }
    },
    closeDialogBatch(){//关闭批量添加客房
      this.batch.active = 0
      this.batch = {//批量添加客房
        dialogBatch: false,
        active: 0,
        roomPlanList: [
          {
            label:'数字楼层+数字房号',
            value:'1'
          },{
            label:'数字楼层+字母房号',
            value:'2'
          },{
            label:'楼层减一加A+数字房号',
            value:'3'
          },{
            label:'楼层减一加A+字母房号',
            value:'4'
          },{
            label:'楼层减一加A+数字房号减一加A',
            value:'5'
          },{
            label:'数字楼层+数字房号减一加A',
            value:'6'
          }
        ],
        first: {
          province: '',
          city: '',
          area: ''
        },
        second: {
          floorCheck: false,
          roomCheck: false,
          temperasList: []
        },
        third: {
          parameterList: [],
            dialogAssociated: false,
            bigList:[],//关联房
            associatedList:[],//未关联房
            associatedList2:[],//右关联房
            selList1:[],//左表勾选
            selList2:[],//右表勾选
            params: {
            identifying: '',
            roomNumPrefix: '',
            floorNumPrefix: '',
            roomNumber: '',
            floor: '',
            community: ''
          },
        },
        fourth: {
          lastStepList:[],//第五步数据表格
          lastSel:[],//第五步勾选
          testKey:0
        },
        updateParams:{
          ids: '',
          maxPeople: '',
          roomType: ''
        }
      }
      this.getDataList();
    },
  //批量修改状态
    modifyState(e,state,text){
      console.log(e,state)
      if (this.dataChosenId.length > 0){
        this.state.hsIds = this.dataChosenId
        this.state.type=e
        this.state.state=state
        console.log(this.state)
        this.$axios({
          method: 'put',
          url: '/hotel/house4store',
          data: this.state
        }).then(res => {
          this.state = {
            hsIds: '',                  
            type: '',                  
            state: '',                    
          }
          if(res.status ==200){
            this.$message({showClose: true,message: `${text+'成功'}`,type: 'success'});
          }
          this.getDataList()
        }).catch(err => {
          this.isError(err,this)
        })
      } else {
        this.$message.warning('至少需要选中一个房间')
      }
    },
  //门店管理
    pageStoreManagement(){
      this.$axios({
        method: 'get',
        url: '/hotel/houseDict/page',
        params: this.storeManagement.data.params
      }).then(res => {
        if (res.data.result.records.length > 0) {
          this.storeManagement.dataList = res.data.result.records
          this.storeManagement.data.total = res.data.result.total
          this.storeManagement.rowData = null
        } else {
          this.storeManagement.dataList = []
          this.storeManagement.data.total = 0
          this.storeManagement.rowData = null
        }
        console.log(this.storeManagement.dataList)
        this.storeManagement.dialogTableStore = true
      }).catch(err => {
        this.isError(err,this)
      })
    },
    storeManagementSearchChange(){//筛选条件改变时
      this.storeManagement.data.params.cursor = 1
      this.pageStoreManagement()
    },
    storeManagementSizeChange(limit){//点击更改显示条数
      this.storeManagement.data.params.limit = limit
      this.pageStoreManagement()
    },
    storeManagementCurrentChange(cursor){//点击更改点前页数
      this.storeManagement.data.params.cursor = cursor
      this.pageStoreManagement()
    },
    storeManagementTableCurrentChange(row){//选中行
      this.storeManagement.rowData = row
    },
    // 2233
    // storeChanceCity(data){//城区选中时
    // console.log(data.area.code,this.storeManagement.store.houseDictPARM)
    //   if (data.area.code) {
    //     this.storeManagement.store.houseDictPARM.hdProvince = data.province.value//省
    //     this.storeManagement.store.houseDictPARM.hdCity = data.province.value//市
    //     this.storeManagement.store.houseDictPARM.hdDistrict = data.area.value//区
    //   }
    //   // else {
    //   //   this.storeManagement.store.houseDictPARM.hdProvince = ""//省
    //   //   this.storeManagement.store.houseDictPARM.hdCity = ""//市
    //   //   this.storeManagement.store.houseDictPARM.hdDistrict = ""//区
    //   // }
    // },
    onChangeProvince(data){
      console.log(data);
      this.storeManagement.store.houseDictPARM.hdProvince = data.value
    },
    onChangeCity(data){
      this.storeManagement.store.houseDictPARM.hdCity = data.value
    },
    onChangeArea(data){
      this.storeManagement.store.houseDictPARM.hdDistrict = data.value
    },
    closeDialogTableStore(){//清空门店管理
      this.storeManagement.data = {//数据分页查询
        params: {
          hdDistrict: '',//城区
          hdRoad: '',//地址
          hdCommunity: '',//门店
          limit: 10,//每页的条数
          cursor: 1,//初始页
        },
        total: 0,//总条数
        pages: 0,//每页数据
      }
      this.storeManagement.data.params.dataList = []
      this.storeManagement.data.params.rowData = null
    },
    async test(){
      // let map = new BMap.Map("maps");
      // 创建地址解析器实例
      let myGeo = new this.bmap.Geocoder();
      // 将地址解析结果显示在地图上，并调整地图视野
      this.storeManagement.store.houseDictPARM.hdProvince = this.storeManagement.store.houseDictPARM.hdProvince ? this.storeManagement.store.houseDictPARM.hdProvince : ''
      this.storeManagement.store.houseDictPARM.hdCity = this.storeManagement.store.houseDictPARM.hdCity ? this.storeManagement.store.houseDictPARM.hdCity : ''
      this.storeManagement.store.houseDictPARM.hdDistrict = this.storeManagement.store.houseDictPARM.hdDistrict ? this.storeManagement.store.houseDictPARM.hdDistrict : ''
      this.storeManagement.store.houseDictPARM.hdRoad = this.storeManagement.store.houseDictPARM.hdRoad ? this.storeManagement.store.houseDictPARM.hdRoad : ''
      this.storeManagement.store.houseDictPARM.hdCommunity = this.storeManagement.store.houseDictPARM.hdCommunity ? this.storeManagement.store.houseDictPARM.hdCommunity : ''
      let address = `${this.storeManagement.store.houseDictPARM.hdProvince} ${this.storeManagement.store.houseDictPARM.hdCity} ${this.storeManagement.store.houseDictPARM.hdDistrict} ${this.storeManagement.store.houseDictPARM.hdRoad} ${this.storeManagement.store.houseDictPARM.hdCommunity}`
      await myGeo.getPoint(address, async(point)=>{
        this.storeManagement.store.houseDictPARM.hdBaiduLatitudeY = point.lat
        this.storeManagement.store.houseDictPARM.hdBaiduLongitudeX = point.lng
      }
      ,this.storeManagement.store.houseDictPARM.hdCity);
    },
    handler({BMap, map}) {
      this.bmap = BMap
      this.map = map
    },
    //添加门店
      addStore(){//添加门店
        this.storeManagement.store.state = true
        this.storeManagement.store.title = '添加'
        this.storeManagement.store.houseDictPARM.hdProvince = sessionStorage.getItem('companyRentProvince')
        this.storeManagement.store.houseDictPARM.hdCity = sessionStorage.getItem('companyRentCity')
        this.storeManagement.store.dialogStore = true
      },
      addStoreSave(){//添加门店保存
        if (this.storeManagement.store.houseDictPARM.hdDistrict) {
          if (this.submitForm(this.storeManagement.store.ruleArray,this)) {
            this.$axios({
              method: 'post',
              url: '/hotel/houseDict',
              data: this.storeManagement.store.houseDictPARM
            }).then(res => {
              this.$message.success('添加成功')
              this.pageStoreManagement()
              this.getCityList()
              this.storeManagement.store.dialogStore = false
            }).catch(err => {
              this.isError(err,this)
            })
          }
        } else {
          this.$message.warning('城区不能为空')
        }
      },
    //修改门店
      modifyStore(){//修改门店
        this.$axios({
          method: 'get',
          url: `/hotel/houseDict/${this.storeManagement.rowData.hdId}`,
        }).then(res => {
          this.storeManagement.store.state = false
          this.storeManagement.store.title = '修改'
          for (let i in res.data.result) {
            for (let k in this.storeManagement.store.houseDictPARM) {
              if (i == k) {
                this.storeManagement.store.houseDictPARM[k] = res.data.result[i]
              }
            }
          }
          console.log(res.data.result)
          this.storeManagement.store.dialogStore = true
        }).catch(err => {
          this.isError(err,this)
        })
      },
      modifyStoreSave(){//修改门店保存
        if (this.storeManagement.store.houseDictPARM.hdDistrict) {
          if (this.submitForm(this.storeManagement.store.ruleArray,this)) {
            this.$axios({
              method: 'put',
              url: `/hotel/houseDict/${this.storeManagement.rowData.hdId}`,
              data: this.storeManagement.store.houseDictPARM
            }).then(res => {
              this.$message.success('修改成功')
              this.pageStoreManagement()
              this.storeManagement.store.dialogStore = false
            }).catch(err => {
              this.isError(err,this)
            })
          }
        } else {
          this.$message.warning('城区不能为空')
        }
      },
    closeDialogStore(){
      this.resetForm(this.storeManagement.store.ruleArray,this)
      this.storeManagement.store.houseDictPARM = {
        hdProvince: '',//省
        hdCity: '',//市
        hdDistrict: '',//区
        hdRoad: '',//地址
        hdBaiduLongitudeX: '',//经度-x
        hdCommunity: '',//楼盘
        hdBaiduLatitudeY: '',//纬度-y
      }
      this.storeManagement.store.houseDictPARM.hdProvince = ''
      this.storeManagement.store.houseDictPARM.hdCity = ''
      this.storeManagement.store.houseDictPARM.hdDistrict = ''
    },
  //红外监测设备
    bindingDevice() {
      if (this.rowData) {
        this.infrared.loading = true
        this.infrared.dialogBindingDevice = true
        this.infrared.allMonitorDevices = []
        this.infrared.monitoredDevices = []
        this.$axios({
          method: 'get',
          url: '/common2/infraredMonitoring/getAllDevice',
          params: {
            hsId: this.rowData.hsId
          },
        }).then(res => {
          let result = res.data.result
          console.log(result)
          this.infrared.allMonitorDevices = result.allMonitorDevices
          for (let i of result.monitoredDevices) {
            this.infrared.monitoredDevices.push(i.jimDeviceId)
          }
          this.infrared.loading = false
        }).catch(err => {
          this.infrared.loading = false
          this.isError(err,this)
        })
      } else {
        this.$message.warning('请先选择房间！')
      }
    },
    bindingDeviceSave() {
      this.infrared.loading = true
      this.$axios({
        method: 'post',
        url: '/common2/infraredMonitoring/addInfraredMonitoring',
        params: {
          deviceList: this.infrared.monitoredDevices.join(','),
          hsId: this.rowData.hsId,
        }
      }).then(res => {
        this.$message.success('绑定成功')
        this.infrared.dialogBindingDevice = false
        this.infrared.loading = false
      }).catch(err => {
        this.infrared.loading = false
        this.isError(err,this)
      })
    },
    cleanBindingDevice() {
      this.infrared = {//红外监测设备
        loading: false,
        dialogBindingDevice: false,
        allMonitorDevices: [],//所有设备
        monitoredDevices: [],//已绑定设备
      }
    },
  //打印二维码
    printCode(){//打印二维码按钮
      let co=sessionStorage.getItem('co')
      this.printCodeForm.urlList=[]
      if(this.dataChosenArray.length>0){
        // let host = window.location.host
        // if (host != 'www.fangzhizun.com' || host != 'jd.86xc.net') {
        //   host = 'nts1.fzz1.cn:10050'
        // }
        // let pathname = window.location.pathname == '/hotel-admin/' ? '/hotel-admin/#' : ''
        this.dataChosenArray.forEach(item=>{
          let obj = {
            url:`https://www.fangzhizun.com/lock/#/index?co=${co}&hsId=${item.hsId}`,
            // url:`http://fzz1.cn/a?c=${co}&h=${item.hsId}`,
            address:item.address
          }
          this.printCodeForm.urlList.push(obj)
        })
        this.printCodeForm.dialogprintCode=true
        console.log(this.printCodeForm.urlList)
      }else{
        this.$message.warning('请勾选需要打印的房间')
      }
    },
  }
}
</script>
<style lang="scss" scoped>
  .screenInput{
  width: 125px;
  }
  p{
  margin: 0;
  padding-left: 10px;
  font-size: 16px;
  }
  .divwrap >>> select {
    margin-top: 6px;
    padding: 0 15px;
    height: 28px;
    font-size: 0.875rem;
    border-radius: 5px;
  }
  .input {
    .buttons{
      margin: 0 0 0 5px;
      // padding: 0 0;
    }

  }
</style>
