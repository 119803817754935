<template>
    <el-dialog
      title="客控码"
      :visible="customerVisible"
      width="1200px"
      @close="closeDialog"
    >
      <div style="padding-bottom:20px">
        <el-button type="primary" @click="showAddCustomerCode">添加客控码</el-button>
      </div>
      <el-form :inline="true" label-width="80px">
        <el-form-item label="房间地址:">
              <el-input clearable v-model="setValue" size="mini" @change="SetQueryParmasChange"/>
        </el-form-item>
        <el-form-item label="状态">
          <el-select size="mini" clearable v-model="setValue2" @change="queryParmasChange">
              <el-option label="使用中" value="使用中"></el-option>
              <el-option label="未使用" value="未使用"></el-option>
              <el-option label="已过期" value="已过期"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-dialog
        @close="closeAddCustomerCodeShow"
        append-to-body
        :title="title"
        :visible.sync="addCustomerCodeShow"
        width="width"
      >
        <el-row style="padding-bottom:20px">
            <el-col style="padding-top:10px" :span="3.5"><span>房间地址：</span></el-col>
            <el-col :span="10">
              <el-input v-if="this.title=='添加客控码'" v-model="rowVal" disabled></el-input>
              <el-input v-if="this.title=='修改客控码'" v-model="this.currentRow.allName" disabled></el-input> 
            </el-col>
            <el-col :span="4" v-if="this.title=='添加客控码'"><el-button @click="rentedTrace">选 择</el-button></el-col>
        </el-row>
        <el-row style="padding-bottom:20px">
            <span>有效期限：</span>
               <el-date-picker
                :disabled="!disableTitle"
                 v-model="value1"
                 type="datetimerange"
                 :picker-options="pickerOptions"
                 range-separator="至"
                 start-placeholder="开始日期"
                 end-placeholder="结束日期"
                 align="right">
               </el-date-picker>
        </el-row>
        <el-row v-if="this.title=='修改客控码'">
           <span>状态：</span>
            <el-select v-model="getValue" placeholder="请选择" style="width:200px"
            @change="stateChange"
            :disabled="!disableTitle"
            >
             <el-option
               v-for="item in customerStatus"
               :key="item.value"
               :label="item.label"
               :value="item.value">
             </el-option>
           </el-select>
        </el-row>
      <div slot="footer">
        <el-button @click="addCustomerCodeShow = false">取 消</el-button>
        <el-button v-if="this.title=='添加客控码'"  type="primary" @click="addCustomerCode">确 定</el-button>
        <el-button v-if="this.title=='修改客控码'" type="primary" @click="addCustomerCode">{{disableTitle?'确 定':'修 改'}}</el-button>
      </div>
      </el-dialog>
      <el-table
        :data="findCustomerControl"
        highlight-current-row
        style="width: 100%"
        border
        height="374px"
      >
       <el-table-column
         prop="ccaState"
         label="状态"
       ></el-table-column>
       <el-table-column
         style="color:red"
         prop="allName"
         label="房间地址"
       ></el-table-column>
       <el-table-column
         prop="ccaGuestCode"
         label="客控码"
       >
       <template slot-scope="scope">
         <div style="color:green">{{scope.row.ccaGuestCode}}</div>
       </template>
       </el-table-column>
       <el-table-column
         prop="ccaStartTime"
         label="开始时间"
       ></el-table-column>
       <el-table-column
         prop="ccaEndTime"
         label="结束时间"
       ></el-table-column>
       <el-table-column label="操作">
         <template slot-scope="scope">
           <el-button type="primary" @click="setCustomerCode(scope.row,scope.$index)">修改</el-button>
         </template>
       </el-table-column>
      </el-table>
      <div class="center">
        <el-pagination
          background 
          :page-sizes="[5,10]"
           @size-change="handleSizeChange"
          @current-change="changePageCustomer"
          layout="sizes, prev, pager, next"
          :page-size="params2.limit"
          :total="total2">
        </el-pagination>
      </div>
      <el-dialog
        title="房间地址"
        :visible.sync="rentedShow"
        append-to-body
        width="900px"
      >
        <el-table ref="table" 
          highlight-current-row 
          @row-click="handleSelectionChange"
          row-key="hsId" 
          border
          :data="dataList" height="290" v-loading="loading">
          <el-table-column label="城区" prop="hsAddDistrict"/> 
          <el-table-column label="门店" prop="hsAddCommunity"/>
          <el-table-column label="房型" prop="hsRoomType"/>
          <el-table-column label="楼层" prop="hsAddFloor"/>
          <el-table-column label="门牌" prop="hsAddDoorplateno"/>
        </el-table>
        <div class="center">
          <el-pagination
          background
          @current-change="changePage"
          :page-size="params.limit"
          layout="prev, pager, next"
          :total="total" />
        </div>
        <div slot="footer">
          <el-button @click="rentedShow = false">取 消</el-button>
          <el-button type="primary" @click="delRentedShow">确 定</el-button>
        </div>
      </el-dialog>
    </el-dialog>
</template>

<script>
import {getHouse4store} from '@/api/equipmentList'
export default {
   props:{
        customerVisible:Boolean
    },
    data(){
       return{
         setCheckVal:{
           setValue:"",
           setValue2:"",
         },
         setValue:"",
         setValue2:"",
         disableTitle:false,
         title:"",
         addCustomerCodeShow:false,
         findCustomerControl:[],
         value1: [],
         rentedShow:false,
         getValue:"",
         rowVal:"", // 房间地址
         customerStatus:[
           {
             label:'未使用',
             value:'未使用',
           },
           {
             label:'使用中',
             value:'使用中',
           },
         ],
         stateValue:"",
         params:{
           limit:5,
           cursor:1,
           hsAddCommunity:'',
           hsAddDistrict:'',
           hsAddDoorplateno:''
         },
         params2:{
           limit:5,
           cursor:1,
         },
         loading:false,
         dataList:[],
         total:1,
         total2:1,
         pickerOptions: {
          shortcuts: [{
            text: '近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() + 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
       }
    },
    methods:{
      closeDialog(){
        this.$emit('handleCustomerDialogClose')
      },
      // 关闭弹窗初始化
      closeAddCustomerCodeShow(){
        this.value1 = [];
        this.rowVal = '';
        this.title = '';
        this.disableTitle = false;
      },
      // 添加修改
      setCustomerCode(row,index){
        this.title = "修改客控码";
        let {ccaEndTime,ccaStartTime} = row;
        this.currentRow=row;
        this.$set(this.value1,0,ccaStartTime);
        this.$set(this.value1,1,ccaEndTime);
        this.addCustomerCodeShow =true;
      },
      showAddCustomerCode(){
        this.title = "添加客控码";
        this.value1 = [new Date(), new Date(new Date().setDate(new Date().getDate()+1))];
        this.addCustomerCodeShow =true;
        this.disableTitle = true;
      },
      // end
      // 房间地址
      rentedTrace(){
        this.rentedShow =true;
        this.getRoomPageData();
      },
      getRoomPageData(){
         getHouse4store(this.params).then(res => {
            this.loading = false
            this.total = res.data.result.total
            this.dataList = res.data.result.records
            }).catch(() => {
                this.loading = false
                 this.total = 0
            })
      },
      changePage(val){
        this.params.cursor = val
        this.getRoomPageData()
      },
      handleSelectionChange(val){
        this.roomDddressData =val
      },
      delRentedShow(){
        this.rentedShow =false
        let {hsAddDistrict,hsAddCommunity,hsAddFloor,hsAddDoorplateno} = this.roomDddressData;
        this.rowVal =`${hsAddDistrict}${hsAddCommunity}${hsAddFloor?hsAddFloor:""}${hsAddDoorplateno}`
      },
      changePageCustomer(val){
        this.params2.cursor =val
        this.getCustomerCode();
      },
      handleSizeChange(val){
        this.params2.limit = val
        this.getCustomerCode();
      },
      // end
      // 添加修改客控码
      addCustomerCode(){
        // console.log(this.DateFormat(this.value1[0]))
        if(this.title =="添加客控码"){
          if(this.rowVal == ''){
            this.$message({
             message:'房间地址不能为空！',
             type: 'warning'
            });
            return
          }
          this.$axios({
            url:"/common1/customerControlAuthority/createCustomerCode",
            method:"post",
            params:{
              hsId:this.roomDddressData.hsId, 
              startTime:this.DateFormat(this.value1[0]),
              endTime:this.DateFormat(this.value1[1]),
              tutId:"", // 总人口表id
            }
          }).then(res=>{
             this.addCustomerCodeShow=false;
             this.$message.success("添加成功");
          }).catch(err=>{
               this.isError(err, this)
          })
        }else if(this.title == "修改客控码"){
          if(!this.disableTitle){
            this.disableTitle =!this.disableTitle
            return
          }else if(this.disableTitle){
            this.disableTitle =false
            if(this.getValue ==''){
               this.$message({
                 message:'状态不能为空！',
                 type: 'warning'
               });
                return
            }
            let {ccaId,ccaHsId:ccaHsId} =this.currentRow;
            this.$axios({
              url:"/common1/customerControlAuthority/updateCustomerControl",
              method:"put",
              params:{
                ccaHsId, 
                ccaStartTime:this.DateFormat(this.value1[0]),
                ccaEndTime:this.DateFormat(this.value1[1]),
                ccaTutId:"", // 总人口表id
                ccaState:this.stateValue,
                ccaId
              }
            }).then(res=>{
               this.addCustomerCodeShow=false;
               this.$message.success("修改成功");
            }).catch(err=>{
               this.isError(err, this)
            })
          }
        }
        this.getCustomerCode();
      },
      // 查询接口
      getCustomerCode(){
        this.$axios({
          url:"/common1/customerControlAuthority/findCustomerControl",
          method:"get",
          params:{
            ccaState:this.setCheckVal.setValue,
            address:this.setCheckVal.setValue2,
            cursor:this.params2.cursor,
            limit:this.params2.limit,
          }
        }).then(res=>{
          this.total2 = res.data.total
          this.findCustomerControl = res.data.records
        }).catch(err=>{
               this.isError(err, this)
        })
      },
      // 修改状态
      stateChange(val){
        this.stateValue=val
      },
      // 条件查询
      queryParmasChange(val){
        this.setCheckVal.setValue=val
        this.getCustomerCode();
      },
      SetQueryParmasChange(row){
        this.setCheckVal.setValue2=row
        this.getCustomerCode();
      },
      // end
    },
    created(){
      this.getCustomerCode();
    },
}
</script>

<style scoped>
.center{
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>