<template>
        <el-dialog
        title="添加授权"
        :visible.sync="dialogVisible"
        width="900px"
        append-to-body
        :before-close="handleAddAuthClose">
            <div>
                <el-form label-width="70px">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="城区">
                                <el-select style="width:160px" clearable @change="getSceneInfo" size="mini" v-model="params.hsAddDistrict">
                                    <el-option v-for="(item,index) in cityList" :key="index" :label="item.hdDistrict" :value="item.hdDistrict"/>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="房间名称">
                                <el-input style="width:160px" @change="getSceneInfo" size="mini" v-model="params.hsAddCommunity"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="门牌搜索">
                                <el-input style="width:160px" @change="getSceneInfo" size="mini" v-model="params.hsAddDoorplateno"/>                    
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <el-table ref="table"  
                @selection-change="handleSelectionChange"
                row-key="hsId" 
                :data="dataList" height="350" v-loading="loading">
                    <el-table-column type="selection" :reserve-selection="true"/>
                    <el-table-column label="城区" prop="hsAddDistrict"/>
                    <el-table-column label="门店" prop="hsAddCommunity"/>
                    <el-table-column label="房型" prop="hsRoomType"/>
                    <el-table-column label="楼层" prop="hsAddFloor"/>
                    <el-table-column label="门牌" prop="hsAddDoorplateno"/>
                </el-table>
                <div class="center">
                    <el-pagination
                    background
                    @current-change="changePage"
                    :page-size="params.limit"
                    layout="prev, pager, next"
                    :total="total" />
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleAddAuthClose">取 消</el-button>
                <el-button type="primary" @click="handleAddAuthClose('保存')">添加</el-button>
            </span>
        </el-dialog>
</template>

<script>
import { getHouse4store } from '@/api/equipmentList'
import { addAuthorize } from '@/api/room'
import { mapState } from 'vuex'
export default {
    props:{
        dialogVisible:Boolean
    },
    data(){
        return {
            params:{
                limit:5,
                cursor:1,
                hsAddCommunity:'',
                hsAddDistrict:'',
                hsAddDoorplateno:''
            },
            loading:false,
            dataList:[],
            total:1,
            hsIdsList:[],
        }
    },
     computed:{
        ...mapState(['cityList']),
    },
    methods:{
        handleAddAuthClose(flag){
            if(flag === '保存'){
                // 当没有选中时 不能添加授权码
                if(this.hsIdsList.length > 0){
                    const yaCo = sessionStorage.getItem('co')
                    addAuthorize({ yaCo, hsIds:this.hsIdsList}).then(res => {
                        this.$message({
                            type:'success',
                            message:'添加成功'
                        })
                         Object.assign(this.params, this.$options.data().params)
                        this.$refs['table'].clearSelection()
                         this.$emit('handleClose',true)
                    })
                    // info = true
                    return
                }
                this.$message({
                    type:'warning',
                    message:'您还没勾选需要添加授权码的房间不能点击添加哦'
                })
                return
            }
            Object.assign(this.params, this.$options.data().params)
            this.$refs['table'].clearSelection()
            this.$emit('handleClose',false)
        },
        changePage(val){
            this.params.cursor = val
            this.getSceneInfo()
        },
        handleSelectionChange(val){
            console.log(val);
            this.hsIdsList = val.map(item => item.hsId)
            console.log(this.hsIdsList);
        },
        getSceneInfo(){
            this.loading = true
            getHouse4store(this.params).then(res => {
                this.loading = false
                this.total = res.data.result.total
                this.dataList = res.data.result.records
            }).catch(() => {
                this.loading = false
            })
        }
    }
}
</script>
<style scoped>
.center{
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>