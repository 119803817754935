import request from "../utils/request";

export function getRoomPage(params) {
  return request({
    url: "/common2/yijiayinAuth/page",
    method: "GET",
    params
  });
}

export function addAuthorize(data) {
  return request({
    url: "/common2/yijiayinAuth/addAuthorize",
    method: "POST",
    data,
  });
}

export function updAuthorize(data) {
  return request({
    url: "/common2/yijiayinAuth/updAuthorize",
    method: "PUT",
    data,
  });
}
